@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    font-family: 'Roboto', sans-serif;
    color: #212121;
    /* overflow-x: hidden; */
  }
}
@media (min-width: 640px) {
  .container {
    max-width: none;
  }
}
@media (min-width: 1440px) {
  .container {
    max-width: 1400px;
    padding-left: 0;
    padding-right: 0;
  }
}

@layer components {
  /* EXAMPLE COMPONENT - This won't be included in your compiled CSS unless you actually use it */
  h1 {
    font-size: 44px;
    line-height: 50px;
    font-weight: 700;
  }
  h2 {
    font-size: 32px;
    line-height: 46px;
    font-weight: 900;
  }
  h3 {
    font-size: 24px;
    line-height: 34px;
    font-weight: 700;
  }
  h4 {
    font-size: 20px;
    line-height: 29px;
    font-weight: 700;
  }
  h5 {
    font-size: 18px;
    line-height: 26px;
    font-weight: 900;
  }
  label {
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
  }
  p,
  span,
  li,
  input {
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
  }
  input::placeholder {
    color: #575757;
  }
  input {
    color: #212121;
  }
  .button-text {
    font-size: 18px;
    line-height: 26px;
  }
  .text-extra-small {
    font-size: 14px;
    line-height: 19px;
    font-weight: 500;
  }
  .text-small {
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
  }
  .text-large {
    font-size: 20px;
    line-height: 29px;
    font-weight: 700;
  }
  @media (max-width: 1024px) {
    .headerSpacing {
      margin-top: 185px;
    }
  }
  @media (max-width: 768px) {
    .button {
      background-color: white;
    }
    h1 {
      font-size: 32px;
      line-height: 38px;
    }
    h2 {
      font-size: 24px;
      line-height: 34px;
    }
    h3 {
      font-size: 18px;
      line-height: 26px;
    }
    h4 {
      font-size: 18px;
      line-height: 26px;
    }
    h5 {
      font-size: 18px;
      line-height: 26px;
    }
    p,
    span,
    li,
    label,
    input {
      font-size: 16px;
      line-height: 21px;
    }
    .button-text {
      font-size: 16px;
      line-height: 23px;
    }
    .text-small {
      font-size: 12px;
      line-height: 21px;
    }
  }
  .sentence-uppercase:first-letter {
    text-transform: uppercase;
  }
  .button {
    font-size: 1.125rem;
    border-radius: 5px;
    color: #212121;
    background-color: white;
    font-weight: bold;
    line-height: 26px;
    padding: 0.65rem 1.3rem;
    text-align: center;
  }
  @media (max-width: 640px) {
    .button {
      width: 100%;
      justify-content: center;
      border-radius: 10px;
    }
  }
  .button span {
    font-weight: bold;
  }
  .button-long {
    padding: 0.75rem 2.35rem;
  }
  @media (min-width: 768px) {
    .button-large {
      font-size: 1.3rem;
      padding: 1rem 2rem;
    }
  }
  @media (max-width: 768px) {
    .button,
    .button-large {
      font-size: 1rem;
      line-height: 23px;
    }
  }
  .button-module {
    padding: 0.75rem 1.25rem;
    min-width: 135px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    width: auto;
  }
  .button-wide {
    padding: 0.75rem 1.25rem;
  }
  .button-ultra-wide {
    padding: 0.75rem 11.25rem;
  }
  .button-radius-large {
    border-radius: 10px;
  }
  .secondary-button-primary,
  .secondary-button-secondary {
    color: white;
    background: #212121;
  }
  .hr {
    width: 100px;
    margin-top: 1rem /* 16px */;
    margin-bottom: 1.5rem /* 24px */;
    height: 0.25rem /* 4px */;
    border-width: 0px;
  }
  @media (max-width: 1028px) {
    .hr {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .button.secondary-button-primary:hover {
    background-color: var(--branding-primary);
    opacity: unset;
  }

  .secondary-button-secondary:hover {
    background-color: var(--branding-secondary);
    opacity: unset;
  }
  .button:hover {
    cursor: pointer;
  }
  .button:hover {
    background-color: #ffffff85;
  }
  .button.secondary-button-primary:hover {
    background-color: var(--branding-secondary);
    color: #212121;
  }
  .button.secondary-button-primary:hover svg path {
    stroke: #000000;
  }
  .button.bg-branding-primary:hover {
    background-color: var(--branding-primary-hover);
  }
  .button.bg-branding-secondary:hover {
    background-color: var(--branding-secondary-hover);
  }
  .button.bg-branding-info:hover {
    background-color: var(--branding-info-hover);
  }
  .button.nav-button-primary:hover {
    background-color: #ffffff;
    color: var(--branding-primary);
  }
  .button.nav-button-secondary:hover {
    background-color: unset;
    color: #ffffff;
  }
  .text-branding-primary {
    color: var(--branding-primary);
  }
  .bg-branding-primary {
    background-color: var(--branding-primary);
  }
  .fill-branding-primary {
    fill: var(--branding-primary);
  }
  .fill-white {
    fill: #fff;
  }
  .bg-transparent {
    background-color: transparent;
  }
  .text-branding-secondary {
    color: var(--branding-secondary);
  }
  .bg-text-field {
    background-color: #f2f2f2;
  }
  .bg-branding-secondary {
    background-color: var(--branding-secondary);
  }
  .border-branding-primary {
    border-color: var(--branding-primary);
  }
  .border-branding-secondary {
    border-color: var(--branding-secondary);
  }
  .border-link {
    border-color: #005e85;
  }
  .text-branding-info {
    color: var(--branding-info);
  }

  .bg-branding-info {
    background-color: var(--branding-info);
  }

  .text-error {
    color: red;
  }
  .bg-error {
    background-color: red;
  }

  .text-correct {
    color: #497000;
  }
  .text-grey {
    color: #575757;
  }

  .text-main {
    color: #212121;
  }
  .border-main {
    color: #212121;
  }
  .bg-main {
    background-color: #212121;
  }
  .bg-grey {
    background-color: #575757;
  }
  .bg-light-grey {
    background-color: #f7f8f9 !important;
  }
  .border-light-grey {
    border-color: #f7f8f9;
  }
  .border-grey {
    border-color: #575757;
  }
  .border-light-gray {
    border-color: lightgray;
  }
  .border-bottom-grey {
    border-bottom: 1px solid #575757;
  }
  .section-border-grey {
    border-bottom: 1px solid rgba(213, 215, 220, 0.39);
  }
  .card {
    width: 100%;
  }
  .shadow-left {
    box-shadow: -4px 0px 5px -2px rgba(0, 0, 0, 0.12);
  }

  .shadow-bottom {
    box-shadow: 0px 3px 9px -4px rgba(0, 0, 0, 0.2);
  }

  .rounded-small {
    border-radius: 5px;
  }
  .rounded-medium {
    border-radius: 10px;
  }
  .rounded-large {
    border-radius: 20px;
  }
  .rounded-cap {
    border-radius: 100px;
  }
  .font-black {
    font-weight: 900;
  }

  .custom-after-100::after {
    content: '';
    display: block;
    width: 100px;
    height: 3px;
    background-color: currentColor;
    opacity: 0.5;
    margin-top: 22px;
  }
  .drop-shadow-4xl {
    --tw-drop-shadow: drop-shadow(0 25px 25px rgb(0 0 0 / 0.3));
    filter: var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale)
      var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia)
      var(--tw-drop-shadow);
  }

  .testimonials .carousel.carousel-slider {
    padding-bottom: 4rem;
  }

  .testimonials .carousel .control-dots {
    margin: 0;
  }

  .bg-white .carousel .control-dots .dot,
  .bg-light-grey .testimonials .carousel .control-dots .dot {
    background: #575757;
  }

  .testimonials .carousel .control-dots .dot {
    background: white;
    opacity: 30%;
    border: none;
    width: 30%;
    height: 3px;
    max-width: 60px;
    border-radius: 15px;
    box-shadow: none;
  }

  .bg-branding-primary .testimonials .carousel .control-dots .dot.selected {
    background: white; /*CAN WE GET BRANDING COLOURS HERE?*/
    opacity: 100%;
  }
  .bg-white .testimonials .carousel .control-dots .dot.selected {
    background: var(--branding-primary); /*CAN WE GET BRANDING COLOURS HERE?*/
    opacity: 100%;
  }
  .bg-light-grey .testimonials .carousel .control-dots .dot.selected {
    background: var(--branding-primary); /*CAN WE GET BRANDING COLOURS HERE?*/
    opacity: 100%;
  }

  .courses .carousel-wrapper .carousel .slide {
    display: flex !important;
  }

  .courses .carousel-wrapper .carousel .slide > a {
    flex: 1;
    margin-right: 1rem;
    display: flex;
  }
  .courses .carousel .control-arrow,
  .courses .carousel.carousel-slider .control-arrow {
    opacity: 1;
    bottom: 25%;
  }
  .courses .carousel .control-arrow:before,
  .courses .carousel.carousel-slider .control-arrow:before {
    content: ' ';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23ffffff'%3E%3Cpath d='M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 90%;
    background-color: #212121;
    border-radius: 200px;
    border: 2px solid white;
    width: 45px;
    height: 45px;
    box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.37);
  }
  .courses .carousel .control-prev.control-arrow:before {
    transform: rotate(180deg);
  }
  .courses .carousel .control-prev.control-arrow {
    left: -20px;
    display: none !important;
  }
  .courses .carousel .control-next.control-arrow {
    right: -10px;
  }
  .courses .carousel.carousel-slider .control-arrow:hover {
    background: rgba(0, 0, 0, 0);
  }
  .footer-links {
    font-size: 16px;
    line-height: 30px;
    font-weight: 700;
    margin-left: 1rem;
  }
  .footer-links::before {
    content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: white; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
  }
  .active-border-bottom span {
    padding: 11px 0;
    font-weight: 700 !important;
    border-bottom: 4px solid #000;
  }
  .page-coursedetail .whatlearn ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
    gap: 1rem;
    list-style-type: none;
    padding: 0;
    width: 100%;
  }
  .booking-panel {
    border-radius: 1rem;
    max-width: 35%;
    width: 338px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    right: -12%;
    position: absolute;
  }
  @media (max-width: 1028px) {
    .booking-panel {
      position: fixed;
      width: 100vw;
      height: 100px;
      padding: 0;
      margin: 0 auto;
      max-width: 100%;
      right: 0;
      bottom: 0;
      border-radius: 0;
      box-shadow: 0px -4px 20px rgba(50, 50, 50, 0.25);
    }
  }
  @media (max-width: 640px) {
    .page-coursedetail .whatlearn ul {
      grid-template-columns: repeat(1, 1fr);
    }
    .testimonials .carousel.carousel-slider {
      padding-bottom: 3rem;
    }
    .carousel .slider-wrapper {
      width: 65%;
      margin: auto;
    }
  }
  .page-coursedetail .whatlearn li {
    list-style: none;
    display: flex;
    align-items: flex-start;
    padding-top: 55px;
    position: relative;
    margin-bottom: 20px;
    font-weight: 500;
  }
  .page-coursedetail .whatlearn li:before {
    content: ' '; /* background: var(--branding-primary);
   
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%var(--branding-primary)'%3E%3Cpath d='M20.285 2L9 13.567 3.714 8.556l-2.857 2.858L9 19.425 23.142 5.143z'/%3E%3C/svg%3E");
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%var(--branding-primary)'%3E%3Cpath d='M20.285 2L9 13.567 3.714 8.556l-2.857 2.858L9 19.425 23.142 5.143z'/%3E%3C/svg%3E"); */
    mask-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="36.028" height="36.028" viewBox="0 0 36.028 36.028"><path id="check-circle-1-svgrepo-com" d="M26.854,14.009,15.844,25.936l-4.587-4.587m8.257,14.679A16.514,16.514,0,1,0,3,19.514,16.514,16.514,0,0,0,19.514,36.028Z" transform="translate(-1.5 -1.5)" fill="none" stroke="%234831d4" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/></svg>');
    -webkit-mask-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="36.028" height="36.028" viewBox="0 0 36.028 36.028"><path id="check-circle-1-svgrepo-com" d="M26.854,14.009,15.844,25.936l-4.587-4.587m8.257,14.679A16.514,16.514,0,1,0,3,19.514,16.514,16.514,0,0,0,19.514,36.028Z" transform="translate(-1.5 -1.5)" fill="none" stroke="%234831d4" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/></svg>');
    background-repeat: no-repeat;
    background-color: var(--branding-primary);
    position: absolute;
    top: 0;
    background-position: center;
    width: 45px;
    height: 45px;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    /* box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.37); */
  }
  .accordion {
    content: ' ';
    background-color: var(--branding-primary);
    position: absolute;
    top: 22px;
    right: 20px;
    width: 35px;
    height: 17.5px;
    background-position: center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
  .accordion-close {
    mask-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="35" height="17.5" viewBox="0 0 30.239 17.119"><path id="arrow-down-simple-svgrepo-com" d="M4,8,16.291,20.291,28.582,8" transform="translate(31.41 22.291) rotate(180)" fill="none" stroke="%23585858" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/></svg>');
    -webkit-mask-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="17.5" viewBox="0 0 30.239 17.119"><path id="arrow-down-simple-svgrepo-com" d="M4,8,16.291,20.291,28.582,8" transform="translate(31.41 22.291) rotate(180)" fill="none" stroke="%23585858" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/></svg>');
  }
  .accordion-open {
    mask-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="35" height="17.5" viewBox="0 0 30.239 17.119"><path id="arrow-down-simple-svgrepo-com" d="M4,8,16.291,20.291,28.582,8" transform="translate(-1.172 -5.172)" fill="none" stroke="%23585858" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/></svg>');
    -webkit-mask-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="35" height="17.5" viewBox="0 0 30.239 17.119"><path id="arrow-down-simple-svgrepo-com" d="M4,8,16.291,20.291,28.582,8" transform="translate(-1.172 -5.172)" fill="none" stroke="%23585858" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/></svg>');
  }
  .testimonial-slider-icon {
    content: ' ';
    width: 27.5px;
    height: 44px;
    background-position: center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
  .testimonial-slider-next {
    mask-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="27.5" height="44" viewBox="0 0 27.5 44"><defs><clipPath id="clip-path"><rect id="Rectangle_2481" data-name="Rectangle 2481" width="27.5" height="44" fill="%23f08219"/></clipPath></defs><g id="Group_2391" data-name="Group 2391" transform="translate(27.5 44) rotate(180)"><g id="Group_2389" data-name="Group 2389" clip-path="url(%23clip-path)"><path id="Path_6436" data-name="Path 6436" d="M22,44a5.482,5.482,0,0,1-3.889-1.611l-16.5-16.5a5.5,5.5,0,0,1,0-7.778l16.5-16.5a5.5,5.5,0,1,1,7.778,7.778L13.278,22,25.889,34.611A5.5,5.5,0,0,1,22,44" transform="translate(0 0)" fill="%23f08219"/></g></g></svg>');
    -webkit-mask-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="27.5" height="44" viewBox="0 0 27.5 44"><defs><clipPath id="clip-path"><rect id="Rectangle_2481" data-name="Rectangle 2481" width="27.5" height="44" fill="%23f08219"/></clipPath></defs><g id="Group_2391" data-name="Group 2391" transform="translate(27.5 44) rotate(180)"><g id="Group_2389" data-name="Group 2389" clip-path="url(%23clip-path)"><path id="Path_6436" data-name="Path 6436" d="M22,44a5.482,5.482,0,0,1-3.889-1.611l-16.5-16.5a5.5,5.5,0,0,1,0-7.778l16.5-16.5a5.5,5.5,0,1,1,7.778,7.778L13.278,22,25.889,34.611A5.5,5.5,0,0,1,22,44" transform="translate(0 0)" fill="%23f08219"/></g></g></svg>');
  }
  .testimonial-slider-prev {
    mask-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="27.5" height="44" viewBox="0 0 27.5 44"><path id="Path_6436" data-name="Path 6436" d="M22,44a5.482,5.482,0,0,1-3.889-1.611l-16.5-16.5a5.5,5.5,0,0,1,0-7.778l16.5-16.5a5.5,5.5,0,1,1,7.778,7.778L13.278,22,25.889,34.611A5.5,5.5,0,0,1,22,44" transform="translate(0 0)" fill="%23fff"/></svg>');
    -webkit-mask-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="27.5" height="44" viewBox="0 0 27.5 44"><path id="Path_6436" data-name="Path 6436" d="M22,44a5.482,5.482,0,0,1-3.889-1.611l-16.5-16.5a5.5,5.5,0,0,1,0-7.778l16.5-16.5a5.5,5.5,0,1,1,7.778,7.778L13.278,22,25.889,34.611A5.5,5.5,0,0,1,22,44" transform="translate(0 0)" fill="%23fff"/></svg>');
  }
  @media (max-width: 640px) {
    .testimonial-slider-icon {
      width: 20px;
      height: 32px;
    }
    .testimonial-slider-next {
      mask-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="32" viewBox="0 0 27.5 44"><defs><clipPath id="clip-path"><rect id="Rectangle_2481" data-name="Rectangle 2481" width="27.5" height="44" fill="%23f08219"/></clipPath></defs><g id="Group_2391" data-name="Group 2391" transform="translate(27.5 44) rotate(180)"><g id="Group_2389" data-name="Group 2389" clip-path="url(%23clip-path)"><path id="Path_6436" data-name="Path 6436" d="M22,44a5.482,5.482,0,0,1-3.889-1.611l-16.5-16.5a5.5,5.5,0,0,1,0-7.778l16.5-16.5a5.5,5.5,0,1,1,7.778,7.778L13.278,22,25.889,34.611A5.5,5.5,0,0,1,22,44" transform="translate(0 0)" fill="%23f08219"/></g></g></svg>');
      -webkit-mask-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="32" viewBox="0 0 27.5 44"><defs><clipPath id="clip-path"><rect id="Rectangle_2481" data-name="Rectangle 2481" width="27.5" height="44" fill="%23f08219"/></clipPath></defs><g id="Group_2391" data-name="Group 2391" transform="translate(27.5 44) rotate(180)"><g id="Group_2389" data-name="Group 2389" clip-path="url(%23clip-path)"><path id="Path_6436" data-name="Path 6436" d="M22,44a5.482,5.482,0,0,1-3.889-1.611l-16.5-16.5a5.5,5.5,0,0,1,0-7.778l16.5-16.5a5.5,5.5,0,1,1,7.778,7.778L13.278,22,25.889,34.611A5.5,5.5,0,0,1,22,44" transform="translate(0 0)" fill="%23f08219"/></g></g></svg>');
    }
    .testimonial-slider-prev {
      mask-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="32" viewBox="0 0 27.5 44"><path id="Path_6436" data-name="Path 6436" d="M22,44a5.482,5.482,0,0,1-3.889-1.611l-16.5-16.5a5.5,5.5,0,0,1,0-7.778l16.5-16.5a5.5,5.5,0,1,1,7.778,7.778L13.278,22,25.889,34.611A5.5,5.5,0,0,1,22,44" transform="translate(0 0)" fill="%23fff"/></svg>');
      -webkit-mask-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="32" viewBox="0 0 27.5 44"><path id="Path_6436" data-name="Path 6436" d="M22,44a5.482,5.482,0,0,1-3.889-1.611l-16.5-16.5a5.5,5.5,0,0,1,0-7.778l16.5-16.5a5.5,5.5,0,1,1,7.778,7.778L13.278,22,25.889,34.611A5.5,5.5,0,0,1,22,44" transform="translate(0 0)" fill="%23fff"/></svg>');
    }
  }
  /* For Webkit-based browsers (Chrome, Safari and Opera) */
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }

  /* For IE, Edge and Firefox */
  .scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .container.container-mui {
    max-width: 1440px;
  }

  .iframe-video iframe,
  .iframe-video object,
  .iframe-video embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .testimonials .carousel .rounded-full img {
    border: 2px solid #999;
  }
  .active-border-bottom span {
    border-bottom: 4px solid var(--branding-primary);
  }
  .sidebarButton {
    min-width: 80px;
    max-width: 80px;
    min-height: 80px;
    max-height: 80px;
    font-size: 14px;
    margin: 1.5rem 0;
    position: relative;
  }
  .sidebarButton:hover {
    color: white;
    opacity: 1;
  }
  .sidebarButton:hover > svg,
  .sidebarButton:hover > span {
    color: white;
    opacity: 1 !important;
  }
  .sidebarButton.active {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .module-sidenav {
    min-width: 100px;
    overflow: hidden auto;
    position: relative;
  }
  @media (max-width: 1023px) {
    .module-sidenav {
      display: none !important;
    }
  }
  .sectionContent3,
  .sectionContent2 {
    width: 100%;
  }
  .bottomShadow::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 50px;
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, #fff, transparent);
    z-index: 0;
  }
  @media (min-width: 1536px) {
    .sectionContent2 {
      width: 47.5%;
    }
    .sectionContent3 {
      width: 30%;
    }
  }
  .sectionContent p,
  .sectionContent2 p,
  .sectionContent3 p {
    margin-bottom: 15px;
  }
  iframe {
    max-width: 100%;
  }
  @media (min-width: 1536px) {
    .sidebarButton {
      min-width: 90px;
      max-width: 90px;
      min-height: 90px;
      max-height: 90px;
      margin: 7.5px 0;
    }
    .module-sidenav {
      min-width: 105px;
    }
    .sidebarCurve {
      right: 105px;
    }
  }
  @media (min-width: 1700px) {
    .sidebarButton {
      min-width: 90px;
      max-width: 90px;
      min-height: 90px;
      max-height: 90px;
      margin: 12px 0;
    }
    .module-sidenav {
      min-width: 115px !important;
      max-width: 115px !important;
    }
    .sidebarCurve {
      right: 115px;
    }
  }
  .module-sidenav::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  .DrawerWrapper {
    width: 400px !important;
    background: #fff;
  }
  @media (max-width: 1023px) {
    .DrawerWrapper {
      position: fixed;
      width: 100% !important;
      height: 100vh;
      left: 0;
      top: 17%;
      z-index: 98;
    }
  }
  .InnerDrawer {
    width: 100% !important;
    height: 100vh !important;
  }
  @media (max-width: 639px) {
    .headerBar {
      padding: 15px;
    }
  }
  @media (min-width: 640px) and (max-width: 1023px) {
    .headerBar {
      padding: 24px;
    }
  }
  .headerWrapper {
    width: 100%;
  }
  @media (max-width: 1023px) {
    .headerWrapper {
      position: fixed;
      width: 100%;
      z-index: 100;
    }
    .headerBar {
      margin: 0 -24px;
      width: 100%;
    }
    .headerBar .slideHeading {
      color: #fff !important;
    }
    .headerTopbar {
      background: #f7f8f9;
      width: 100%;
      margin: 0 -24px;
      z-index: 109;
    }
    .notes {
      border-left: 2px solid #dfdfdf;
      border-right: 2px solid #dfdfdf;
    }
    .desktop-hr {
      display: none;
    }
  }
  @media (min-width: 1024px) {
    .headerTopbar {
      display: none !important;
    }
  }

  .notes-description::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 50px;
    top: 125px;
    left: 0;
    background: linear-gradient(0deg, #fff, transparent);
    z-index: 0;
  }
  .OpenInNewIcon {
    color: #005e85;
  }
  .complete-section circle {
    fill: var(--branding-secondary);
  }
  .ProgressRoot {
    position: relative;
    overflow: hidden;
    border-radius: 99999px;
    width: 200px;
    height: 12px;
    /* Fix overflow clipping in Safari */
    /* https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0 */
    transform: translateZ(0);
  }
  @media (max-width: 1023px) {
    .ProgressRoot {
      width: 100px;
      height: 8px;
    }
  }
  .progressbar {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    flex-wrap: wrap;
  }
  @media (min-width: 1024px) {
    .progressbar:before {
      content: 'Your progress';
      padding-left: 10px;
      min-width: 125px;
      padding-bottom: 10px;
    }
    .ProgressRoot {
      margin-bottom: 10px;
    }
  }
  .ProgressIndicator {
    width: 100%;
    border-radius: 99999px;
    height: 100%;
    transition: transform 660ms cubic-bezier(0.65, 0, 0.35, 1);
  }
  .resourse-panel {
    height: calc(100vh - 250px);
    overflow: hidden auto;
  }
  .resourse-panel::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  .module-contents-item-icon-visible > button,
  .module-contents-item-icon-invisible > button {
    padding-left: 20px;
    padding-right: 20px;
  }
  .module-contents-item-icon-visible:after {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10.414" height="18.828" viewBox="0 0 10.414 18.828"><path id="arrow-forward-simple-svgrepo-com" d="M9,20l8-8L12.333,7.333,9,4" transform="translate(-7.586 -2.586)" fill="none" stroke="%23575757" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>');
    content: '';
    display: inline-block;
    width: 12px;
    height: 24px;
    position: absolute;
    right: 23px;
    top: calc(50% - 12px);
    background-size: contain;
    background-repeat: no-repeat;
  }
  .home-icon path {
    stroke: #575757;
  }
  .share-icon path,
  .profile-icon path {
    stroke: #fff;
  }
  path#left-f-svgrepo-com:hover {
    fill: #575757;
  }
  .module-contents-item-icon-visible button:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 15px;
    top: calc(50% - 10px);
    background-size: contain;
    background-repeat: no-repeat;
  }

  .module-contents-item-icon-visible.notstarted button:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='19' viewBox='0 0 19 19'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_2504' data-name='Rectangle 2504' width='8.597' height='6.959' fill='%23fff'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Group_2420' data-name='Group 2420' transform='translate(-870 -329)'%3E%3Ccircle id='Ellipse_36' data-name='Ellipse 36' cx='9.5' cy='9.5' r='9.5' transform='translate(870 329)' fill='%23000000'/%3E%3Cg id='Group_2419' data-name='Group 2419' transform='translate(874.785 335)'%3E%3Cg id='Group_2418' data-name='Group 2418' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_6454' data-name='Path 6454' d='M.239,3.515a.819.819,0,0,0,0,1.158L2.286,6.72a.819.819,0,0,0,.579.24h.016A.819.819,0,0,0,3.467,6.7L8.379,1.374A.819.819,0,0,0,7.176.264l-4.334,4.7L1.4,3.515a.819.819,0,0,0-1.158,0' transform='translate(0 0)' fill='%23ffffff'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }
  .module-contents-item-icon-visible.inprogress button {
    padding-left: 20px;
  }
  .module-contents-item-icon-visible.complete button {
    background-color: #f6f6f6;
  }
  .module-contents-item-icon-visible.complete button:before {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxNicgaGVpZ2h0PScyMScgdmlld0JveD0nMCAwIDE2IDIxJz48cGF0aCBkPSdNMTIsMTR2Mk04LDlWNmE0LDQsMCwwLDEsOCwwVjlNNywyMUgxN2EyLDIsMCwwLDAsMi0yVjExYTIsMiwwLDAsMC0yLTJIN2EyLDIsMCwwLDAtMiwydjhBMiwyLDAsMCwwLDcsMjFaJyB0cmFuc2Zvcm09J3RyYW5zbGF0ZSgtNCAtMSknIGZpbGw9J25vbmUnIHN0cm9rZT0nIzIxMjEyMScgc3Ryb2tlLWxpbmVjYXA9J3JvdW5kJyBzdHJva2UtbGluZWpvaW49J3JvdW5kJyBzdHJva2Utd2lkdGg9JzInPjwvcGF0aD48L3N2Zz4=');
  }

  .module-content-section p,
  .module-content-section ul li {
    margin-bottom: 8px;
  }

  .module-content-section a {
    color: var(--branding-primary);
    text-decoration: underline;
  }

  .module-content-section ul,
  .module-content-section ol {
    margin: 20px 0 20px 20px;
  }

  .module-content-section ul li {
    list-style-type: disc;
    margin: 5px 0;
  }

  .module-content-section ol li {
    list-style-type: auto;
    margin: 5px 0;
  }

  .module-content-section .content-with-image-img {
    display: block;
    max-width: 100%;
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
  }
  .module-content-section h1,
  .module-content-section h2,
  .module-content-section h3,
  .module-content-section h4,
  .module-content-section h5,
  .module-content-section h6 {
    margin: 0px 0 16px;
  }

  .content-panel-contents {
    height: calc(100vh - 250px);
    overflow: hidden auto;
  }
  .content-panel-contents::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  .note-item {
    position: relative;
  }
  .note-actions {
    position: absolute;
    right: 0;
    height: 30px;
    width: 30px;
    border-radius: 200px;
    text-align: center;
    font-weight: bold;
    line-height: 20px;
  }
  .top-tab-width {
    width: calc(33%);
  }
  .note-actions:hover {
    cursor: pointer;
  }
  .note-actions .popup {
    position: absolute;
    right: 10px;
    top: 40px;
    background: white;
    box-shadow: 1px 1px 9px -2px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    padding: 10px;
  }
  .note-actions .popup button {
    display: block;
    width: 135px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
    text-align: left;
    padding-left: 40px;
    background-size: 15px;
    background-position: left 8px center;
    background-repeat: no-repeat;
  }
  .note-actions .popup button.edit-note {
    background-image: url('/src/assets/icon/edit-note.svg');
  }
  .note-actions .popup button.delete-note {
    color: #b30000;
    background-image: url('/src/assets/icon/delete-note.svg');
  }
  .note-readmore {
    border: 0;
    padding: 0 !important;
    color: #005e85 !important;
    font-weight: bold;
    font-size: 16px;
  }
  .note-readmore:focus {
    box-shadow: none !important;
  }
  .note-panel-notes {
    height: calc(100vh - 365px);
    overflow: hidden auto;
  }
  .note-panel-notes::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  .note-panel-notes .note-item:last-child hr {
    display: none;
  }
  .note-panel-addNote {
    height: calc(100vh - 250px);
    overflow: hidden auto;
  }
  .note-panel-addNote::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  button.add-note-butt {
    background-size: 15px;
    background-position: left 22px center;
    background-repeat: no-repeat;
    background-image: url('/src/assets/icon/add-note.svg');
  }
  .list-options {
    border: 2px solid transparent;
  }
  .list-options.active {
    border: 2px solid #000;
  }

  .question-answer-item label:hover {
    cursor: pointer;
  }

  .question-submit:disabled {
    opacity: 0.2;
  }

  .z-1000 {
    z-index: 1000px;
  }

  .standard-link {
    text-decoration: 'none';
    cursor: 'pointer';
    padding-left: 42;
    padding-right: 42;
    height: 48;
    font-size: 18;
    font-weight: 'bold';
    border-radius: 8;
    width: 'fit-content';
    white-space: 'nowrap';
    background-repeat: 'no-repeat';
    background-position: 'left 15px center';
    display: 'flex';
    align-items: 'center';
    margin-bottom: 31;
  }
  .module-sidenav button.side-nav-butt svg.sidebar-icon {
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentcolor;
    flex-shrink: 0;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 2.5rem;
    max-width: 40px;
    max-height: 40px;
  }

  .loadingSpinner {
    width: 100px !important;
    height: 100px !important;
    position: absolute;
    left: 50%;
    margin-left: -50px;
    top: 45%;
    margin-top: -50px;
  }
  .contents-description {
    width: calc(100% - 55px);
  }
  @media (max-width: 1023px) {
    .clippy-shadow {
      display: none;
    }
  }
  @media (min-width: 1024px) {
    .clippy-shadow {
      background: var(--branding-primary);
      height: 30px;
      position: fixed;
      right: 100px;
      width: 25px;
      z-index: 1;
    }
    .rounded-border {
      width: 100%;
      height: 100%;
      background-color: #fff;
    }
    .clippy-shadow.shadow-top-right {
      top: 0;
    }
    .clippy-shadow.shadow-bottom-right {
      bottom: 0;
    }
    .clippy-shadow.shadow-top-right .rounded-border {
      border-radius: 0 100% 0 0;
    }
    .clippy-shadow.shadow-bottom-right .rounded-border {
      border-radius: 0 0 100% 0;
    }
  }
  @media (min-width: 1536px) {
    .clippy-shadow {
      right: 105px;
    }
  }
  @media (min-width: 1700px) {
    .clippy-shadow {
      right: 115px;
    }
  }
  /* Small devices (phones, 640px and up) */
  @media (min-width: 640px) {
    /* Your CSS here */
  }

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    /* Your CSS here */
  }

  /* Large devices (desktops, 1024px and up) */
  @media (min-width: 1024px) {
    /* Your CSS here */
  }

  /* Extra large devices (large desktops, 1280px and up) */
  @media (min-width: 1440px) {
    .module-content-section .content-with-image-img {
      margin-bottom: 30px;
      border-radius: 30px;
    }
  }

  /* Extra extra large devices (larger desktops, 1440px and up) */
  @media (min-width: 1440px) {
    /* Your CSS here */
  }

  @media (max-height: 790px) {
    .module-sidenav .org-logo {
      max-width: 70px;
      max-height: 70px;
      margin: 20px auto 0 auto !important;
    }
    .module-sidenav button.side-nav-butt {
      margin: 0px auto 0 auto !important;
    }
    .module-sidenav button.side-nav-butt svg,
    .module-sidenav button.side-nav-butt svg.sidebar-icon {
      max-width: 30px;
      max-height: 30px;
      margin: 0 auto;
    }
  }
}
.close-navbar {
  margin-right: 10px;
  color: #fff !important;
}
@media (min-width: 1024px) {
  .close-navbar {
    display: none !important;
  }
}
.header.site_header + * {
  margin-top: 88px;
}
@media (min-width: 640px) {
  .header.site_header + * {
    margin-top: 100px;
  }
}
.auth-input-field {
  border: 1px solid #212121;
  box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.2);
}
.forgot-password-link {
  color: #005e85;
  text-decoration: underline;
}
.link {
  color: #005e85;
  text-decoration: underline;
}
/******** Custom Scrollbar *********/
@media (min-width: 576px) {
  body {
    overflow-y: overlay !important;
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: transparent;
    cursor: pointer !important;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(200, 200, 200, 0.4);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(200, 200, 200, 0.4);
    cursor: pointer;
  }
}

/******** Slick Slider *********/
.slick-prev:before,
.slick-next:before {
  font-size: 25px;
  opacity: 1;
  color: #212121;
}
.slick-prev,
.slick-next {
  width: 60px;
  height: 60px;
  z-index: 9;
  top: 6.5rem;
}
.slick-prev {
  left: -15px;
}
.slick-next {
  right: -15px;
}
.slick-next:before {
  margin-left: -7px;
}
.slick-slide > div {
  margin: 0 15px;
}
@media (min-width: 1024px) {
  .slick-slide:only-child {
    max-width: 50%;
    float: none;
    margin: 0 auto;
  }
}
@media (max-width: 480px) {
  .slick-list {
    padding: 0 5% 0 0 !important;
  }
}
/********** Toast color *************/
.Toastify__toast--success {
  background: #497000 !important;
}
.Toastify__toast--error {
  background: #b30000 !important;
}

/********** Navbar *************/
.hamburgerIcon {
  position: absolute;
  right: 24px;
  text-align: center;
  text-align: -webkit-center;
}
@media (min-width: 1024px) {
  .mobileView {
    display: none;
  }
}
@media (max-width: 1023px) {
  .desktopView {
    display: none;
  }
}

@media (min-width: 1280px) {
  .two-content-items-wrapper {
    grid-template-columns: 1fr 1fr;
  }
  .two-content-items-wrapper.oneFrametwoFrame {
    grid-template-columns: 1fr 2fr;
  }
  .two-content-items-wrapper.twoFrameoneFrame {
    grid-template-columns: 2fr 1fr;
  }
  .two-content-items-wrapper.oneFrameoneFrame {
    grid-template-columns: 1fr 1fr;
  }
  .three-content-items-wrapper {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.preview-mode {
  z-index: 1000;
}
.pending-notification {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
